import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import AnarcraftImage from '../components/AnarcraftImage.jpg';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CopyToClipboard from 'react-copy-to-clipboard';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';


const LoginPage = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [playerInfo, setPlayerInfo] = useState({ online: 0, max: 0, version: 'N/A', players: [] });
  const [playerStats, setPlayerStats] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [modVersion, setModVersion] = useState('');

  const modpack_url = `https://minecraft.kaito.link/Modpack%20${encodeURIComponent(modVersion)}%20Fabric%20Anarcraft.zip`;


  useEffect(() => {
    // Effettua una richiesta HTTP per ottenere la versione dal tuo file JSON
    fetch('https://minecraft.kaito.link/mod_version.json')
      .then((response) => response.json())
      .then((data) => {
        // Estrai la versione dal JSON
        const version = data.mod_version;
        console.log("mod version ", version);
        setModVersion(version);
        console.log(modVersion);
      })
      .catch((error) => {
        console.error('Errore durante il recupero della versione:', error);
      });
  }, []);

  const fetchPlayerInfo = async () => {
    try {
      const response = await fetch('https://minecraft.kaito.link/auth.php?command=status', {
        method: 'GET',
        headers: {
        },
      });

      const data = await response.json();
      setPlayerInfo(data.response);
    } catch (error) {
      console.error('Errore durante la richiesta:', error);
    }
  };

  const fetchPlayerStats = async () => {
    try {
      const response = await fetch('https://minecraft.kaito.link/auth.php?command=stats', {
        method: 'GET',
        headers: {
        },
      });

      const data = await response.json();
      setPlayerStats(data.response);
    } catch (error) {
      console.error('Errore durante la richiesta:', error);
    }
  };

  useEffect(() => {
    fetchPlayerInfo();
    const intervalId = setInterval(fetchPlayerInfo, 10000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    fetchInitialPlayerInfo();
  }, []);

  useEffect(() => {
    fetchPlayerStats();
    const intervalId = setInterval(fetchPlayerStats, 10000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    fetchInitialPlayerStats();
  }, []);

  const fetchInitialPlayerInfo = async () => {
    try {
      const response = await fetch('https://minecraft.kaito.link/auth.php?command=status', {
        method: 'GET',
        headers: {
        },
      });

      const data = await response.json();
      setPlayerInfo(data.response);
    } catch (error) {
      console.error('Errore durante la richiesta:', error);
    }
  };

  const fetchInitialPlayerStats = async () => {
    try {
      const response = await fetch('https://minecraft.kaito.link/auth.php?command=stats', {
        method: 'GET',
        headers: {
        },
      });

      const data = await response.json();
      setPlayerStats(data.response);
    } catch (error) {
      console.error('Errore durante la richiesta:', error);
    }
  };

  useEffect(() => {
    fetchInitialPlayerInfo();
  }, []);

  useEffect(() => {
    // Questo useEffect verrà eseguito ogni volta che modVersion cambia
    console.log("modVersion:", modVersion);
    const modpack_url = `https://minecraft.kaito.link/Anarcraft%20Server.zip`;
  }, [modVersion]);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://minecraft.kaito.link/auth.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
      });

      const data = await response.json();
      if (data.success) {
        console.log(data.token);
        navigate('/protected', {state: {token: data.token}});
      } else {
        console.error('Errore durante l\'autenticazione');
      }
    } catch (error) {
      console.error('Errore durante la chiamata API', error);
    }
  };

  const handleCopyToClipboard = () => {
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const PlayerStatsTable = ({ stats }) => {
    // Calcola i primi tre valori più alti per ogni categoria
    const getTopThree = (key) => {
      return stats
        .slice()
        .sort((a, b) => b[key] - a[key])
        .slice(0, 3)
        .map(player => player[key]);
    };
  
    const topDeathsValues = getTopThree('death_counter');
    const topKillsValues = getTopThree('uccisioni');
    const topMinutesValues = getTopThree('total_online_minute');
  
    // Assegna una medaglia in base alla posizione del valore nella lista dei primi tre
    const assignMedal = (value, topValues) => {
      const position = topValues.indexOf(value);
      if (position === 0) return ' 🥇';
      if (position === 1) return ' 🥈';
      if (position === 2) return ' 🥉';
      return '';
    };

    // Converte i minuti in giorni
    const minutesToDaysHours = (minutes) => {
      const days = Math.floor(minutes / 1440);
      const hours = Math.floor((minutes % 1440) / 60);
      let result = '';
      if (days > 0) result += `${days} giorni`;
      if (hours > 0) result += (result ? ', ' : '') + `${hours} ore`;
      return result ? ` (${result})` : '';
    };
  
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nome Giocatore</TableCell>
              <TableCell align="right">Morti Totali</TableCell>
              <TableCell align="right">Uccisioni</TableCell>
              <TableCell align="right">Minuti di gioco</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stats.map((player) => (
              <TableRow
                key={player.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {player.is_admin ? "👨‍💻 " + player.name : "⛏️ " + player.name}
                </TableCell>
                <TableCell align="right">
                  {player.death_counter}{assignMedal(player.death_counter, topDeathsValues)}
                </TableCell>
                <TableCell align="right">
                  {player.uccisioni}{assignMedal(player.uccisioni, topKillsValues)}
                </TableCell>
                <TableCell align="right">
                  {player.total_online_minute}{assignMedal(player.total_online_minute, topMinutesValues)}{minutesToDaysHours(player.total_online_minute)}
              </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <div>
      <AppBar position="static" color="success">
        <Toolbar>
          {isMobile && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setDrawerOpen(true)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img src={AnarcraftImage} alt="Anarcraft Server" style={{ width: '50px', marginRight: '10px' }} />
            <Typography variant="h6" color="inherit">
              Anarcraft
            </Typography>
          </Box>
          {!isMobile && (
            <form onSubmit={handleLogin} style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
              <TextField
                label="Username"
                variant="standard"
                value={username}
                color="warning"
                onChange={(e) => setUsername(e.target.value)}
                required
                style={{ marginRight: '10px', flex: 1 }}
                InputProps={{ style: { borderRadius: '0' } }}
              />
              <TextField
                label="Password"
                type="password"
                variant="standard"
                value={password}
                color="warning"
                onChange={(e) => setPassword(e.target.value)}
                required
                style={{ marginRight: '10px', flex: 1 }}
                InputProps={{ style: { borderRadius: '0' } }}
              />
              <Button type="submit" variant="contained" color="primary" >
                Login
              </Button>
            </form>
          )}
        </Toolbar>
      </AppBar>

      <Drawer anchor="left" open={drawerOpen} onClose={() => setDrawerOpen(false)} sx={{ backgroundColor: theme.palette.background.default }}>
        <form onSubmit={handleLogin} style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <TextField
            label="Username"
            variant="outlined"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            fullWidth
            style={{ marginBottom: '10px' }}
            InputProps={{ style: { borderRadius: '0' } }}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            fullWidth
            style={{ marginBottom: '10px' }}
            InputProps={{ style: { borderRadius: '0' } }}
          />
          <Button type="submit" variant="outlined" color="error">
            Login
          </Button>
        </form>
      </Drawer>

      {/* Griglia per le Cards */}
      <Grid container spacing={2} style={{ marginTop: '20px' }}>
        <Grid item xs={12} md={3}>
          {/* Prima card sulla prima riga */}
          <Card>
            <CardContent>
              <Typography variant="h6">Status Server</Typography>
              <Typography variant="subtitle2" color="textSecondary">Minecraft Version {playerInfo.version.name} Fabric Mod</Typography>
              <Typography variant="h6">Giocatori Online: {playerInfo.online}</Typography>
              <Typography variant="h6">Massimo Giocatori: {playerInfo.max}</Typography>
              <Typography variant="h6">Nomi Giocatori: {playerInfo.players && playerInfo.players.length > 0
                  ? playerInfo.players.join(', ')
                  : 'Nessun giocatore online'}
              </Typography>
              <Typography variant="h6">Versione del Gioco: {playerInfo.version.name}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          {/* Seconda card sulla prima riga */}
          <Card>
            <CardContent>
              <Typography variant="h6">Informazioni Server</Typography>
              <Typography variant="subtitle2" color="textSecondary">Configurazioni di connessione</Typography>

              
              
              <Typography variant="h6"><b>Indirizzo server</b> <CopyToClipboard text="minecraft.kaito.link:7500" onCopy={handleCopyToClipboard}>
                <Typography
                  variant="h6"
                  style={{ cursor: 'pointer', fontFamily: 'monospace' }}
                >
                  minecraft.kaito.link:7500
                </Typography>
              </CopyToClipboard> (click to copy)</Typography>
              <Snackbar
                open={snackbarOpen}
                autoHideDuration={5000}
                onClose={handleCloseSnackbar}
                message="Testo copiato"
                action={
                  <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                }
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          {/* Terza card sulla seconda riga */}
          <Card>
            <CardContent>
              <Typography variant="h6">Strumenti utili</Typography>
              <Typography variant="subtitle2" color="textSecondary">Strumenti utili condivisi con tutti</Typography>
            </CardContent>
            <CardActions>
              <Button size="small" onClick={() => window.open('https://minecraft.kaito.link:7110', '_blank')}>BlueMap: Vedi mappa già esplorata</Button>
          </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          {/* Quarta card sulla seconda riga */}
          <Card>
            <CardContent>
              <Typography variant="h6">Install & Download Mod</Typography>
              <Typography variant="subtitle2" color="textSecondary">Istruzioni per installazioni e download</Typography>
              <Typography variant="h6">
                <b>Version attuale mod</b> {`${modVersion}`}
                <Divider variant="middle" />
                Devi installare da zero un profilo? Clicca <a href={`${modpack_url}`} download>QUI</a> per scaricare
                <Divider variant="middle" />
                Non sai cosa fare? <a href="https://minecraft.kaito.link/Guida%20e%20FAQ%20Anarcraft.pdf" target="_blank">QUI</a> la nostra guida per l'installazione.
                <Divider variant="middle" />
                Cartella Dropbox: <a href="https://www.dropbox.com/scl/fo/hahxvbvx6xxa1hnbpnsug/h?rlkey=utf9r8fz6tz7gdmm0h8nxh8x4&dl=0" target='_blank'>mods</a><br />
                Cartella Dropbox: <a href="https://www.dropbox.com/scl/fo/f6eejcnqyddp7qit0ukcq/h?rlkey=hb94u1c2wwm0r5fonk51feft6&dl=0" target='_blank'>resourcepacks</a><br />
                Cartella Dropbox: <a href="https://www.dropbox.com/scl/fo/nq1o9uctbjtf3iws86cfg/h?rlkey=jliwwpwtyf17cp1qejuoxj2jp&dl=0" target='_blank'>shaderpacks</a><br />
                Cartella Dropbox: <a href="https://www.dropbox.com/scl/fo/pkwyeb3hq1513uh5uauoc/h?rlkey=7krm7c8twrjyq3lqjsbll4cnf&dl=0" target='_blank'>config</a><br />
                </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          {/* Prima card sulla prima riga */}
          <Card>
            <CardContent>
              <Typography variant="h6">Global Player Stats</Typography>
              <Typography variant="subtitle2" color="textSecondary">Statistiche generali sui giocatori</Typography>
              <PlayerStatsTable stats={playerStats} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default LoginPage;
