import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


const ProtectedPage = () => {
  const [command, setCommand] = useState('');
  const [commandHistory, setCommandHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [playerInfo, setPlayerInfo] = useState({ online: 0, max: 0, version: 'N/A', players: [] });
  const navigate = useNavigate();
  const location = useLocation();
  const token = location.state?.token;

  const fetchPlayerInfo = async () => {
    try {
      const response = await fetch(`https://minecraft.kaito.link/auth.php?token=${token}&command=status`, {
        method: 'GET',
        headers: {},
      });

      const data = await response.json();
      setPlayerInfo(data.response); // Imposta i dati del giocatore dal server
    } catch (error) {
      console.error('Errore durante la richiesta:', error);
    }
  };

  // Esegui fetchPlayerInfo al caricamento della pagina
  useEffect(() => {
    fetchPlayerInfo();
  }, []);

  // Esegui fetchPlayerInfo ogni 10 secondi (puoi regolare l'intervallo in base alle tue esigenze)
  useEffect(() => {
    const intervalId = setInterval(fetchPlayerInfo, 10000);

    // Pulisci l'intervallo quando il componente viene smontato
    return () => clearInterval(intervalId);
  }, []); // Esegui l'effetto solo una volta all'avvio del componente

  const fetchInitialPlayerInfo = async () => {
    try {
      const response = await fetch('https://minecraft.kaito.link/auth.php?command=status', {
        method: 'GET',
        headers: {
        },
      });

      const data = await response.json();
      setPlayerInfo(data.response); // Imposta i dati del giocatore dal server
    } catch (error) {
      console.error('Errore durante la richiesta:', error);
    }
  };

  useEffect(() => {
    fetchInitialPlayerInfo();
  }, []); // Esegui l'effetto solo una volta all'avvio del componente

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (command == "status") {
      const updatedHistory = [...commandHistory, { command, response: 'Controllo comando...' }];
      updatedHistory[updatedHistory.length - 1].response = 'Lo status è visibile sopra e nella home in tempo reale.';
    }
    else {
      const updatedHistory = [...commandHistory, { command, response: 'In attesa...' }];
      const url = `https://minecraft.kaito.link/auth.php?token=${encodeURIComponent(token)}&command=${encodeURIComponent(command)}`;
      setCommandHistory(updatedHistory);
      setIsLoading(true);
  
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
          },
        });
  
        const data = await response.json();
        
        updatedHistory[updatedHistory.length - 1].response = data.response;
        setCommandHistory(updatedHistory);
      } catch (error) {
        console.error('Errore durante la richiesta:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleLogout = () => {
    token = null;
    navigate('/');
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message = 'Sei sicuro di voler lasciare la pagina?';
      event.returnValue = message; // Standard per la maggior parte dei browser
      return message; // Per Internet Explorer
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      navigate("/protected", {state: {token: token}});
    };
  }, []);

  return (
    <div>
      {/* Informazioni statistiche */}
      <div>
        <strong>Giocatori Online:</strong> {playerInfo.online}
        <br />
        <strong>Massimo Giocatori:</strong> {playerInfo.max}
        <br />
        <strong>Nomi Giocatori:</strong> {playerInfo.players && playerInfo.players.length > 0
            ? playerInfo.players.join(', ')
            : 'Nessun giocatore online'}
        <br />
        <strong>Versione del Gioco:</strong> {playerInfo.version.name}
        <br />
      </div>
      <br />

      {/* Invio comandi e storico dei comandi */}
      <form onSubmit={handleSubmit}>
        <textarea
          name="command"
          id="command"
          cols="30"
          rows="10"
          value={command}
          onChange={(e) => setCommand(e.target.value)}
        ></textarea>
        <br />
        <button type="submit">Invia comando</button>
      </form>
      <br />

      {/* Mostra lo storico dei comandi e risposte */}
      {commandHistory.slice().reverse().map((cmd, index) => (
        <div key={index}>
          <strong>Comando:</strong> {cmd.command}
          <br />
          <strong>Risposta:</strong> {isLoading && index === commandHistory.length - 1 ? 'In attesa...' : cmd.response}
          <br />
          <br />
        </div>
      ))}

      <a href="/" onClick={handleLogout}>Logout</a>
    </div>
  );
};

export default ProtectedPage;
